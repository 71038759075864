'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { identity } from '@prisma/client'

import { cn } from '@/lib/utils'

import { EmailButton } from './email-button'

type Props = {
  identity?: identity[]
}

export const Hero = ({ identity }: Props) => {
  const pathname = usePathname()

  const backgroundCover = () => {
    switch (pathname) {
      case '/':
        return 'bg-hero bg-cover [background-size:200%] bg-right md:[background-size:150%] lg:bg-cover'

      case '/despre-mine':
        return 'bg-hero-about-me bg-center bg-cover'

      case '/servicii':
        return 'bg-hero-services bg-center bg-cover'

      default:
        return 'bg-hero bg-center bg-cover'
    }
  }

  return (
    <section className='flex flex-col gap-4 md:flex-row'>
      <div
        className={cn(
          backgroundCover(),
          'flex h-72 w-full items-center justify-end p-6 md:h-hero md:p-20'
        )}>
        {pathname === '/' && (
          <p className='relative w-72 pb-8 text-center text-xl font-light text-theme-muted after:absolute after:bottom-0 after:left-12 after:right-12 after:border-b after:border-theme-muted after:content-[""]'>
            Cunoscând dificultatea întoarcerii atenției către interior, ofer un
            spațiu de siguranță pentru fiecare persoană care dorește să-și
            descopere Sinele autentic
          </p>
        )}
      </div>

      <div className='bg-center-bottom hidden h-72 w-full flex-col justify-between bg-hero-info bg-cover bg-left p-3 text-xs text-white md:h-hero md:w-1/3 md:p-10 lg:flex'>
        <div className='font-light leading-9'>
          <p className=''>Programări Psihoterapie</p>
          <div className='border-t border-white'>
            Tel:{' '}
            <a href={`tel:${identity?.[0]?.phone}`}>{identity?.[0]?.phone}</a>
          </div>
          <div className='border-t border-white'>
            <EmailButton emailAddress={identity?.[0]?.email} />
          </div>
          <div className='border-t border-white pt-2.5 text-xs'>
            * {identity?.[0]?.timetable}
          </div>
        </div>
        {pathname === '/' && (
          <p className='font-light italic'>
            Experienta mea terapeutica este una cu adevarat productiva. Pe
            parcursul unui an am invatat sa imi simt emotiile și nu doar sa le
            rationalizez. Am invatat sa fiu blanda cu mine și sa imi ofer
            spatiul emotional sa mă cunosc pe mine cu adevarat, nu doar prin
            perspectiva sociala a persoanei mele…
            <Link
              className='mt-4 block font-bold not-italic'
              href='/despre-mine'>
              […] mai mult
            </Link>
          </p>
        )}
      </div>
    </section>
  )
}
